import React, { useState } from "react";
import "./FilterCard.css";
import { useUserContext } from "../../../context/MemorialContext";

const FilterCard = () => {
  const filterOptions = [
    "All",
    "Baby",
    "Candles",
    "Birthday",
    "Flowers",
    "Miss You",
    "Hobbies",
    "Veterans",
    "Nature",
    "First Responders",
    "Holiday",
  ];

  const [selectedTags, setSelectedTags] = useState([]);
  const { newTheme, setNewThemes, themes: allThemes } = useUserContext();

  const handleTagChange = (tag) => {
    const lowerCaseTag = tag.toLowerCase();
    if (selectedTags[0] === lowerCaseTag) {
      setSelectedTags([]);
      if (lowerCaseTag === "all") {
        setNewThemes(allThemes);
      } else {
        const filteredThemes =
          lowerCaseTag === "all"
            ? allThemes
            : allThemes.filter(
                (theme) =>
                  theme.tags && lowerCaseTag == theme.tags.toLowerCase()
              );
        setNewThemes(filteredThemes);
      }
    } else {
      setSelectedTags([lowerCaseTag]);
      if (lowerCaseTag === "all") {
        setNewThemes(allThemes);
      } else {
        const filteredThemes =
          lowerCaseTag === "all"
            ? allThemes
            : allThemes.filter(
                (theme) =>
                  theme.tags && lowerCaseTag == theme.tags.toLowerCase()
              );
        setNewThemes(filteredThemes);
      }
    }
  };

  console.log("newThemes", allThemes, selectedTags);
  return (
    <div className="filter-bar">
      <h3 className="filter-bar-title">Select a template</h3>
      {/* <p className="filter-bar-description">We have built 45+ <br/> templates for your</p> */}
      <p className="filter-bar-subdesc"> loved ones</p>
      <p className="cat-text">Category</p>
      <div className="filter-options">
        <div className="filter-column">
          {filterOptions.slice(0, 9).map((option) => (
            <div className="filter-option" key={option}>
              <input
                type="radio"
                name="filter"
                id={option}
                checked={selectedTags.includes(option.toLowerCase())}
                onChange={() => handleTagChange(option)}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>

        <div className="filter-column">
          {filterOptions.slice(9).map((option) => (
            <div className="filter-option" key={option}>
              <input
                type="radio"
                name="filter"
                id={option}
                checked={selectedTags.includes(option.toLowerCase())}
                onChange={() => handleTagChange(option)}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>
      </div>
      {/* <button
        onClick={() => {
          console.log("fil", selectedTags);
          if (selectedTags.includes("all") || selectedTags.length == 0) {
            setNewThemes(allThemes);
          } else {
            setNewThemes(filteredThemes);
          }
        }}
        className="saveBtn"
      >
        Save
      </button> */}
    </div>
  );
};

export default FilterCard;
