import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/MemorialContext";
import { updateHasPaid } from "./utils";
import "../about/about.css";

function Success() {
  const navigate = useNavigate();
  const [Address , setAddress] = useState("")

  const { user, setUser, fetchUser } = useUserContext();
  useEffect(() => {
    // Make the backend request to update the hasPaid field

    // Call the function to update the hasPaid field

    if (user) {
      updateHasPaid(user, true, setUser);
      fetchUser();
    }

    // Clean up function
    return () => {
      // Perform any cleanup if needed
    };
  }, []);
  return (
    <div className="aboutpage">
      <div className="ab-top">
        <img src={logo} />
        <div className="content1">
          <p className="tp">Payment Successful</p>
          <p className="btm">
            At willalwaysloveu, we provide a shared, easily-accessible virtual
            space where family members and friends can pay homage to a special
            life while helping each other heal by sharing their feelings, warm
            memories, and words of support.
          </p>
        <div className="addressWrapper">
        
         { localStorage.getItem("package") == 2  && <div>
            <p className="tp">shipping Address for frame</p>
            <textarea
              rows={5}
            
              onChange={(e) => setAddress(e.target.value)}
            />
                  </div>}
          <button
            className="memobtn"
            onClick={() => {
              if(localStorage.getItem("package") == 2){
  
                localStorage.setItem("Address",Address)
              }
              navigate('/creatememorial')
            }}
          >
            Continue
          </button>
          </div>
        </div>
      </div>

      <div className="triagnle"></div>
      <div className="ab-bottom"></div>
    </div>
  );
}

export default Success;
