import React, { useEffect, useState } from "react";
import Vector from "../../../assets/Vector.svg";
import Vector1 from "../../../assets/Vector-1.svg";
import Vector2 from "../../../assets/Vector-2.svg";
import Vector3 from "../../../assets/Vector-3.svg";
import Search from "../../../assets/Search.svg";
import arrow from "../../../assets/icons/arraw.svg";
import "./FindMemorial.css";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const FindMemorial = () => {
  const [memorials, setMemorial] = useState([]);
  const [query, setQuery] = useState("");
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (query !== "") {
        fetch(`${backendUrl}/api/memorials?search=${query}`)
          .then((res) => res.json())
          .then((data) => {
            setMemorial(data.memorials);
          });
      } else {
        setMemorial([]);
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [query]);

  return (
    <div className="grid-container">
      <p className="intro-text">find a memorial</p>
      <div className="grid-row">
        <div className="input-container">
          <input
            type="text"
            placeholder="Search .."
            className="full-width"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <img src={Search} />
        </div>
      </div>
      <ul className="search-results">
        {memorials.map((memorial) => {
          return (
            <li>
              <Link
                to={`/profile/${memorial.firstName + memorial.lastName}/${
                  memorial._id
                }`}
              >
                {memorial.firstName + " " + memorial.lastName}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FindMemorial;
