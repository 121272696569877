const updateHasPaid = (user, hasPaid, setUser) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  fetch(`${backendUrl}/api/users/${user.meta._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ hasPaid }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error updating user");
      }
      return response.json();
    })
    .then((data) => {
      // Handle the response from the backend
      console.log("User updated:", data.user);
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...user, meta: { ...data.user } }),
      );
      setUser({ ...user, meta: { ...data.user } });
    })
    .catch((error) => {
      // Handle any errors that occur during the request
      console.error("Error updating user:", error);
    });
};
export { updateHasPaid };
