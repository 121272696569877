import React from "react";
import "./CreateMemorial.css";
import greater from "../../../assets/icons/greater.svg";
import LovedOne from "../../components/lovedone/LovedOne";
import ChoosePlane from "../../components/creatememo_chooseplane/ChoosePlane";
import MemoLogin from "../../components/creatememo_login/MemoLogin";
import PrivacyOption from "../../components/privacyoption/PrivacyOption";
import { useUserContext } from "../../../context/MemorialContext";

function CreateMemorial() {
  const {
    user,
    options,
    setOptions,
    handleSelected,
    showOption,
    setShowOption,
    formIsCompleted,
  } = useUserContext();

   React.useEffect(() => {
    console.log("Form is Completed; ",user, formIsCompleted);
    setOptions([
      {
        title: "About your loved one",
        isCompleted: true,
        isActive: !formIsCompleted,
        show: true,
        component: <LovedOne title="About your loved one" /> || "",
      },
      {
        title: "Account details",
        isCompleted: false,
        isActive: formIsCompleted && !user,
        show: !user,
        component: <MemoLogin title="Account details" />,
      },
      {
        title: "Choose your plan",
        isCompleted: false,
        isActive: formIsCompleted && user && !user?.meta.hasPaid,
        show: user?.meta.hasPaid ? false : true || false,
        component: <ChoosePlane title="Choose your plan" />,
      },
      {
        title: "Privacy options",
        isCompleted: false,
        isActive: formIsCompleted && user?.meta.hasPaid,
        show: true,
        component: <PrivacyOption title="Privacy options" />,
      },
    ]);
    if (formIsCompleted) {
      if (!user) {
        setShowOption({
          title: "Account details",
          isCompleted: false,
          isActive: false,
          show: !user,
          component: <MemoLogin title="Account details" />,
        });
      } else if (user?.meta.hasPaid) {
        setShowOption({
          title: "Privacy options",
          isCompleted: false,
          isActive: false,
          show: true,
          component: <PrivacyOption title="Privacy options" />,
        });
      } else {
        setShowOption({
          title: "Choose your plan",
          isCompleted: false,
          isActive: false,
          show: user?.meta.hasPaid ? false : true || true,
          component: <ChoosePlane title="Choose your plan" />,
        });
      }
    }else{
      setShowOption({
        title: "About your loved one",
        isCompleted: true,
        isActive: !formIsCompleted,
        show: true,
        component: <LovedOne title="About your loved one" /> || "",
      });

      
    }
  }, []);

  return (
    <div className="memrialpage">
      <h1>Create a memorial website</h1>
      <div className="breadcrum">
        {options.map((item) => {
          return (
            <>
              {item.show && (
                <p
                  className={`${item.isActive == true ? "active" : "noActive"}`}
                  onClick={() => handleSelected(item)}
                >
                  {item.title}

                  <span>
                    <img src={greater} />
                  </span>
                </p>
              )}
            </>
          );
        })}
      </div>
      <div className="breadcrum_component">{showOption.component}</div>
    </div>
  );
}

export default CreateMemorial;
