import Plan from "./Plan";

function ChoosePlane() {

  return (
    <div>
      <Plan/>
    </div>
  );
}

export default ChoosePlane;
