import React, { useEffect, useState } from "react";
import FilterCard from "../../components/filtercard/FilterCard";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { useUserContext } from "../../../context/MemorialContext";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import { updateHasPaid } from "../paymentstatus/utils";
import "./ThemeSelection.css";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ThemeSelection = () => {
  const [open, setOpen] = React.useState(false);
  const [created, setCreated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentTheme, setCurrentTheme] = React.useState();
  const [width, setWidth] = React.useState("lg");
  const {
    themes,
    newThemes,
    setNewThemes,
    setSelectedTheme,
    formData,
    user,
    setCurrentMemorial,
    currentMemorial,
    getMemorialDetails,
    ResetForNewMemo,
    fetchUser,
    setFormIsCompleted,
    memorialID,
    setMemorialID,
    setUser,
    setFormData,
  } = useUserContext();

  const navigate = useNavigate();
  const location = useLocation();
  const { from, selectedMemorial, slug } = location.state || {};
  const { meta } = user;
  const userId = meta._id;
  useEffect(() => {
    setNewThemes(themes);
  }, [themes.length > 0]);

  useEffect(() => {
    if (memorialID) {
      getMemorialDetails(userId, memorialID);
    }
  }, [userId, memorialID]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const addMemorial = async () => {
    setLoading(true);
    const userId = meta._id;

    const data = {
      ...formData,
      memorialId: memorialID !== null || memorialID !== "" ? memorialID : null,
      theme: currentTheme._id,
      user: userId,
      owner: user.email || user.meta.email,
      planId: localStorage.getItem("package"),
    };

    try {
      const response = await fetch(`${backendUrl}/api/memorials`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const data = await response.json();
        updateHasPaid(user, false, setUser);
        fetchUser();
        setCurrentMemorial(data?.memorial);
        setMemorialID(data.memorial._id);
        setLoading(false);
        return data.memorial;
      } else {
        console.error("Error adding memorial:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding memorial:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendEmail = (from, slug, id) => {
    const to = "WillAlwaysLoveUTech@gmail.com";

    const emailData = {
      from: from,
      to: to, // Change this to your backend endpoint
      text: `Here is the memorial Urlfor which you need to make Frame <a>https://willalwaysloveu.com/profile/${slug}/${id} and Shiping Address Where Need to delivered ${localStorage.getItem(
        "Address"
      )}`,
      subject: `Regarding Shiping of Frame `,
    };

    // Make an HTTP POST request to your backend endpoint
    fetch(`${backendUrl}/api/memorials/${id}/sendEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.removeItem("Address");
      })
      .catch((error) => {
        console.error(error);
        // Handle error
      });
  };

  const handleThemeUpdate = async (theme) => {
    try {
      let endpoint = `${backendUrl}/api/memorials/${selectedMemorial}`;
      const response = await axios.put(endpoint, { theme: currentTheme });
      if (response) {
        navigate(`/profile/${slug}/${selectedMemorial}`);
      }
    } catch (error) {}
  };
  const handleCreated = async () => {
    setCreated(true);
    setSelectedTheme(currentTheme);
    if (from === "profile") {
      handleThemeUpdate();
    } else {
      const response = await addMemorial();
      setFormIsCompleted(false);
      if (response) {
        navigate(
          `/profile/${response.firstName + response.lastName}/${response._id}`
        );
        if (localStorage.getItem("package") == 2) {
          sendEmail(
            response.owner,
            response.firstName + response.lastName,
            response._id
          );
        }

        localStorage.removeItem("package");
      }
      setFormData({});
    }
  };

  const handleContinue = () => {
    //Reset the form data and set next memo new page
    setCurrentTheme({});
    ResetForNewMemo();
    if (memorialID) {
      // navigate(`/profile/${currentMemorial.firstName + currentMemorial.lastName}`, { state: { id :memorialID } });
      // navigate(`/profile/${memorialID}`);
      navigate(
        `/profile/${
          currentMemorial.firstName + currentMemorial.lastName
        }/${memorialID}`
      );
    }
  };
  return (
    <div className={`theme-selection-container`}>
      <div className="filter-bar">
        <FilterCard />
      </div>
      <div className="theme-cards-container">
        {newThemes?.map((theme) => (
          <div
            className="theme-card"
            onClick={() => {
              setCurrentTheme(theme);
              handleClickOpen();
            }}
          >
            <img src={theme.thumbnail} alt={theme.tags} loading="lazy" />
          </div>
        ))}

        {/* Repeat the above card structure for each theme */}
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={width}
        onClose={handleClose}
        sx={{
          backdropFilter: "blur(5px) sepia(5%)",
        }}
        // 👇 Props passed to Paper (modal content)
        PaperProps={{ sx: { borderRadius: "15px" } }}
        aria-describedby="alert-dialog-slide-description"
      >
        {memorialID && created && (
          <div className="d-content">
            <p className="dialog-content">
              {formData
                ? `${formData.firstName}'s online memorial successfully created.`
                : "Loading..."}
            </p>
          </div>
        )}

        <div className="actions">
          {created ? (
            <Button
              isLoading={loading}
              onClick={handleContinue}
              text="Continue"
            />
          ) : (
            <div
              style={{
                display: "flex",
                gap: "15px",
                padding: "30px",
              }}
            >
              <button className="chooseBtn" onClick={handleClose}>
                Choose another
              </button>
              <button className="continueBtn" onClick={handleCreated}>
                Continue
              </button>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default ThemeSelection;
