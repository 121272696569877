import React, { useState, useEffect } from "react";
import "./PlaneFeature.css";
import arrow from "../../../assets/icons/arraw.svg";
import tick from "../../../assets/icons/tick.svg";
import { useNavigate } from "react-router-dom";
import getStripe from "../../../utils/getStripe";
import axios from "axios";
function PlaneFeature() {
  const [message, setMessage] = useState();
  const Plans = [{
    price:"$0.00",
    features:["Never Expire","5 Photos"]
},{
    price:"$49.99",
    features:["Never Expire","Unlimited Photos","Video and Music Gallery","Social Media Integration","Custom Background Music","Advanced Privacy Controls","Additional Administrators","Custom Notifications"]
}
,{
    price:"$75.00",
    features:["Never Expire","Unlimited Photos","Video and Music Gallery","Social Media Integration","Custom Background Music","Advanced Privacy Controls","Additional Administrators","Custom Notifications","custom picture frame"]
}]
  const navigate = useNavigate();
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready.",
      );
    }
  }, []);

  const handleSubmit = async (event) => {
    navigate("/creatememorial");
  };

  return (
    <div className="pf-page">
      <div className="pf-top">
        <h2>PLANS AND FEATURES</h2>
      </div>
      <div className="pf-pricing">
        <div className="pricing-text">
          <p>One Price, Endless Memories</p>
          <p>For Your</p>
          <p className="pfont">loved ones</p>
          {/* <img src={arrow} alt="Arrow" /> */}
        </div>
<div style={{display:"flex", gap:"10px"}}>
       {Plans.map((item) => <div className="pricing-card">
          <p className="life">Lifetime</p>
          <h2>{item.price}</h2>
          <p className="flexible">Most flexible</p>

          <p className="divider"></p>

        { item.features.map((item) => <p className="line">
            <img src={tick} alt="Tick" /> <span>{item}</span>
          </p>) }
          <p>{message}</p>

          <button className="get-started" onClick={() => handleSubmit()}>
            Get Started
          </button>
        </div>) }
        </div>
      </div>
      <div className="pfbottom"></div>
    </div>
  );
}

export default PlaneFeature;
