import React, { useState, useEffect } from "react";
import tick from "../../../assets/icons/tick.svg";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { useUserContext } from "../../../context/MemorialContext";
import "./ChoosePlane.css";
import { useNavigate } from "react-router-dom";
import { updateHasPaid } from "../../pages/paymentstatus/utils";

const Plan = () => {
  const navigate = useNavigate();
  const Plans = [
    {
      planName: 0,
      priceId: "price_1PR5VMLZaqlFZMo5EtzoSkud",
      testId: "price_1PR69PLZaqlFZMo5YLUN28iK",
      price: "$0.00",
      features: ["Never Expire", "5 Photos"],
    },
    {
      planName: 1,
      priceId: "price_1Nj1xMLZaqlFZMo5QcT07BYS",
      testId: "price_1NZhA4LZaqlFZMo5xgm73XYL",
      price: "$49.99",
      features: [
        "Never Expire",
        "Unlimited Photos",
        "Video and Music Gallery",
        "Social Media Integration",
        "Custom Background Music",
        "Advanced Privacy Controls",
        "Additional Administrators",
        "Custom Notifications",
      ],
    },
    {
      planName: 2,
      priceId: "price_1PR5WgLZaqlFZMo588bpnvIz",
      testId: "price_1PR69sLZaqlFZMo50NOPQNnz",
      price: "$75.00",
      features: [
        "Never Expire",
        "Unlimited Photos",
        "Video and Music Gallery",
        "Social Media Integration",
        "Custom Background Music",
        "Advanced Privacy Controls",
        "Additional Administrators",
        "Custom Notifications",
        "custom picture frame",
      ],
    },
  ];
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { user, setUser } = useUserContext();
  const [activeButton, setActiveButton] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const { delayedTrigger, fetchUser } = useUserContext();
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const handleSubmit = async (event, priceId, planName) => {
    event.preventDefault();
    if (planName === 0) {
      localStorage.setItem("package", planName);
      navigate("/success");
    } else {
      try {
        const response = await axios.post(
          `${backendUrl}/api/create-checkout-session`,
          {
            userId: user.meta._id,
            priceId: priceId,
          }
        );
        if (response.data.url) {
          localStorage.setItem("package", planName);
          window.location.href = response.data.url;
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <div className="memoLogin-page">
      <div className="memoLogin-pricing1">
        <div className="pricing-text">
          <p>One Price, Endless Memories</p>
          <p>For Your</p>
          <p className="pfont">loved ones</p>
          {/* <img src={arrow} /> */}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          {Plans.map((plan, planIndex) => (
            <div className="memopricing-card1" key={planIndex}>
              <p className="life">Lifetime</p>
              {/* <h2>$49.99</h2> */}
              <h2>{plan.price}</h2>
              <p className="flexible">Most flexible</p>

              <p className="divider"></p>

              <div className="memoLinesDiv">
                {plan?.features?.map((feature, featureIndex) => (
                  <p className="memoline" key={featureIndex}>
                    <img src={tick} alt="tick icon" /> <span>{feature}</span>
                  </p>
                ))}
                <p>{message}</p>
                <button
                  className="memoget-started"
                  onClick={(e) => {
                    setLoading(true);
                    delayedTrigger(() => {
                      if (
                        process.env.REACT_APP_BACKEND_URL ===
                        "http://localhost:5000"
                      ) {
                        handleSubmit(e, plan.testId, plan.planName);
                      } else {
                        handleSubmit(e, plan.testId, plan.planName);
                      }
                    });
                    setActiveButton(planIndex);
                  }}
                >
                  {activeButton == planIndex ? (
                    <ThreeDots
                      height="50"
                      width="50"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Get started"
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pfbottom1"></div>
    </div>
  );
};

export default Plan;
