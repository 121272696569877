import React from "react";
import "./privacy.css";
import logo from "../../../assets/logo.svg";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="aboutpage">
      <div className="ab-top">
        <img src={logo} />
        <div className="content1"></div>
      </div>

      <div className="triagnle"></div>
      <div className="ab-bottom">
        <div className="privacy-policy">
          <h1>Privacy Policy</h1>
          <p>
            Protecting your private information is our priority. This Statement
            of Privacy applies to the website
            <strong> WillAlwaysLoveU.com</strong> and Pine Island Creations, LLC
            which governs data collection and usage. For the purposes of this
            Privacy Policy, unless otherwise noted, all references to Pine
            Island Creations, LLC include
            <strong> www.WillAlwaysLoveU.com</strong> and P I Creations.
          </p>
          <p>
            By using the WillAlwaysLoveU.com website, you consent to the data
            practices described in this statement.
          </p>
          <h3>Collection of your Personal Information</h3>
          <p>
            In order to better provide you with products and services offered, P
            I Creations may collect personally identifiable information, such as
            your:
          </p>
          <ul>
            <li>First and Last Name</li>
            <li>Mailing Address</li>
            <li>E-mail Address</li>
            <li>Payment information</li>
            <li>Pictures</li>
            <li>Videos</li>
            <li>Audios</li>
          </ul>
          <p>
            If you purchase products and services of Pine Island Creations, LLC,
            we collect billing and credit card information. This information is
            used to complete the purchase transaction.
          </p>
          <p>
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services. These may include: (a) registering for
            an account; (b) entering personal information such as name, address,
            email address; (c) upload pictures and videos; (d) upload audios;
            (e) submitting your credit card or other payment information when
            ordering and purchasing products and services. To wit, we will use
            your information for, but not limited to, communicating with you in
            relation to services and/or products you have requested from us. We
            also may gather additional personal or non-personal information in
            the future.
          </p>
          <h3>Use of your Personal Information</h3>
          <p>
            P I Creations collects and uses your personal information to operate
            and deliver the services you have requested.
          </p>
          <p>
            P I Creations may also use your personally identifiable information
            to inform you of other products or services available from P I
            Creations and its affiliates.
          </p>
          <h3>Sharing Information with Third Parties</h3>
          <p>
            P I Creations does not sell, rent or lease its customer lists to
            third parties.
          </p>
          <p>
            P I Creations may, from time to time, contact you on behalf of
            external business partners about a particular offering that may be
            of interest to you. In those cases, your unique personally
            identifiable information (e-mail, name, address, telephone number)
            is transferred to the third party. P I Creations may share data with
            trusted partners to help perform statistical analysis, send you
            email or postal mail, provide customer support, or arrange for
            deliveries. All such third parties are prohibited from using your
            personal information except to provide these services to P I
            Creations, and they are required to maintain the confidentiality of
            your information.
          </p>
          <p>
            P I Creations may disclose your personal information, without
            notice, if required to do so by law or in the good faith belief that
            such action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on P I Creations or the site; (b)
            protect and defend the rights or property of P I Creations; and/or
            (c) act under exigent circumstances to protect the personal safety
            of users of P I Creations, or the public.
          </p>
          <h3>
            Opt-Out of Disclosure of Personal Information to Third Parties
          </h3>
          <p>
            In connection with any personal information we may disclose to a
            third party for a business purpose, you have the right to know:
          </p>
          <ul>
            <li>
              The categories of personal information that we disclosed about you
              for a business purpose.
            </li>
          </ul>
          <p>
            You have the right under the California Consumer Privacy Act of 2018
            (CCPA) and certain other privacy and data protection laws, as
            applicable, to opt-out of the disclosure of your personal
            information. If you exercise your right to opt-out of the disclosure
            of your personal information, we will refrain from disclosing your
            personal information, unless you subsequently provide express
            authorization for the disclosure of your personal information. To
            opt-out of the disclosure of your personal information, visit this
            Web page _________________.
          </p>
          <h3>Right to Deletion</h3>
          <p>
            Subject to certain exceptions set out below, on receipt of a
            verifiable request from you, we will:
          </p>
          <ul>
            <li>Delete your personal information from our records;</li>
            <li>
              Direct any service providers to delete your personal information
              from their records.
            </li>
          </ul>
          <p>
            Please note that we may not be able to comply with requests to
            delete your personal information if it is necessary to:
          </p>
          <ul>
            <li>
              Complete the transaction for which the personal information was
              collected, fulfill the terms of a written warranty or product
              recall conducted in accordance with federal law, provide a good or
              service requested by you, or reasonably anticipated within the
              context of our ongoing business relationship with you, or
              otherwise perform a contract between you and us;
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity; or prosecute those responsible
              for that activity;
            </li>
            <li>
              Debug to identify and repair errors that impair existing intended
              functionality;
            </li>
            <li>
              Exercise free speech, ensure the right of another consumer to
              exercise his or her right of free speech, or exercise another
              right provided for by law;
            </li>
            <li>
              Comply with the California Electronic Communications Privacy Act;
            </li>
            <li>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when our deletion of the
              information is likely to render impossible or seriously impair the
              achievement of such research, provided we have obtained your
              informed consent;
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with your
              expectations based on your relationship with us;
            </li>
            <li>Comply with an existing legal obligation; or</li>
            <li>
              Otherwise use your personal information, internally, in a lawful
              manner that is compatible with the context in which you provided
              the information.
            </li>
          </ul>
          <h3>Children Under Thirteen</h3>
          <p>
            P I Creations does not knowingly collect personally identifiable
            information from children under the age of thirteen. If you are
            under the age of thirteen, you must ask your parent or guardian for
            permission to use this application.
          </p>
          <h3>Opt-Out & Unsubscribe from Third Party Communications</h3>
          <p>
            We respect your privacy and give you an opportunity to opt-out of
            receiving announcements of certain information. Users may opt-out of
            receiving any or all communications from third-party partners of P I
            Creations by contacting us here: - Email:
            WillAlwaysLoveUTech@gmail.com
          </p>
          <h3>E-mail Communications</h3>
          <p>
            From time to time, P I Creations may contact you via email for the
            purpose of providing announcements, promotional offers, alerts,
            confirmations, surveys, and/or other general communication. In order
            to improve our Services, we may receive a notification when you open
            an email from P I Creations or click on a link therein.
          </p>
          <h3>External Data Storage Sites</h3>
          <p>
            We may store your data on servers provided by third party hosting
            vendors with whom we have contracted.
          </p>
          <h3>Changes to this Statement</h3>
          <p>
            P I Creations reserves the right to change this Privacy Policy from
            time to time. We will notify you about significant changes in the
            way we treat personal information by sending a notice to the primary
            email address specified in your account, by placing a prominent
            notice on our application, and/or by updating any privacy
            information. Your continued use of the application and/or Services
            available after such modifications will constitute your: (a)
            acknowledgment of the modified Privacy Policy; and (b) agreement to
            abide and be bound by that Policy.
          </p>
          <h3>Contact Information</h3>
          <div className="contact-details">
            <div className="contact-address">
              Pine Island Creations, LLC
              <br />
              8 The Green STE A
              <br />
              Dover DE 19901
            </div>
            <div className="email-address">
              Email Address:WillAlwaysLoveUTech@gmail.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
