import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/MemorialContext";
import "./AvatarCompoenent.css";
import { BorderBottom, BorderTopOutlined } from "@mui/icons-material";

export default function AvatarComponent() {
  const { user, signOut } = useUserContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { meta } = user;
  return (
    <>
      <div className="mobile" sx={{ BorderTop: "1px solid #333" }}>
        <div
          className="accountIten"
          onClick={() => navigate("/myaccount")}
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          {" "}
          <Avatar src={meta?.displayPicture} /> My account
        </div>
        <div
          className="accountIten"
          onClick={async () => {
            const success = await signOut();
            if (success) {
              sessionStorage.removeItem("user");
              sessionStorage.removeItem("formData");
              window.location.href = "/login";
            }
          }}
        >
          <ListItemIcon className="accountItemIcon">
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </div>
      </div>
      <div className="desktop">
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Account settings">
            <div
              onClick={handleClick}
              size="medium"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 2,
                cursor: "pointer",
              }}
            >
              <Avatar
                src={meta?.displayPicture}
                sx={{ width: 40, height: 40 }}
              />
              {/* <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
            </div>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          sx={{ display: "flex", flexDirection: "column" }}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              right: 16,
              top: 40,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Link
              to="/myaccount"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar src={meta?.displayPicture} /> My account
            </Link>
          </MenuItem>
          <Divider />

          <MenuItem
            onClick={async () => {
              const success = await signOut();
              if (success) {
                sessionStorage.removeItem("user");
                sessionStorage.removeItem("formData");
                window.location.href = "/login";
              }
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
