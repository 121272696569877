import React, { useCallback, useState } from "react";
import "./Login.css";
import arrow from "../../../assets/icons/arraw.svg";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../utils/firebaseConfig";
import { useUserContext } from "../../../context/MemorialContext";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
function ForgetPassword() {
  const [email, setEmail] = React.useState("");
  const navigate = useNavigate();
  const [sendPasswordResetEmail, loading, error] =
    useSendPasswordResetEmail(auth);
  const { delayedTrigger, setError } = useUserContext();

  const [emailError, setEmailError] = useState("");

  const notify = useCallback(
    (error) =>
      toast.error(error, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }),
    [],
  );

  React.useEffect(() => {
    if (error) {
      notify(error);
      setError("");
    }
  }, [error, notify, setError]);

  const handleLogin = async (email) => {
    if (validateEmail(email)) {
      const success = await sendPasswordResetEmail(email);
      if (success) {
        toast.success("Password Reset Email Sent");
        navigate("/login");
      } else {
        notify(error);
      }
    } else {
      notify("Please enter a valid email address");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="login-page">
      <div className="login-top">
        <div className="topcontent">
          <h2>Welcome to the Family </h2>
          <p>
            we provide a shared, easily-accessible virtual space where family
            members and friends can pay homage to a special life while helping
            each other heal by sharing their feelings, warm memories, and words
            of support.
          </p>
        </div>
      </div>

      <div className="login-card">
        <div className="firstLine">
          <h2>Forget</h2>
          <div className="singp">
            <p>Back to login</p>

            <span>
              <b>
                <Link to="/login">Login</Link>
              </b>{" "}
              <img src={arrow} width={30} />
            </span>
          </div>
        </div>

        <div className="loginsingle-line">
          <div className="loginsingle-input">
            <p>Email</p>
            <input
              value={email}
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              onChange={(e) => {
                setEmail(e.target.value);
                if (!validateEmail(e.target.value)) {
                  setEmailError("Please enter a valid email address");
                } else {
                  setEmailError("");
                }
              }}
              onBlur={() => {
                if (!validateEmail(email)) {
                  setEmailError("Please enter a valid email address");
                } else {
                  setEmailError("");
                }
              }}
              style={{ border: `${emailError ? "1.5px solid red" : "none"}` }}
              type="email"
            />
          </div>
          {emailError && <p className="error-message">{emailError}</p>}
        </div>

        <button
          disabled={emailError || loading}
          style={{
            background: `${emailError ? "#88868e" : "#5c5470"}`,
          }}
          className="login"
          onClick={() => {
            delayedTrigger(() => {
              handleLogin(email);
            });
          }}
        >
          {loading ? (
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#fff"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Send Email"
          )}
        </button>
      </div>
      <div className="Lbottom"></div>
    </div>
  );
}

export default ForgetPassword;
